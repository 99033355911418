import { JIDO_SEISEI_EFAX, ALL_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "title": "Efax\u9001\u4FE1", "visible": _vm.isVisible, "ok-text": "\u9001\u4FE1", "cancel-text": "\u30AD\u30E3\u30F3\u30BB\u30EB", "ok-button-props": { props: { disabled: !_vm.isSendAllEfaxPermission } } }, on: { "cancel": _vm.handleCancel, "ok": _vm.handleOK } }, [_c("text-area", { key: "emailContent", attrs: { "label": "\u672C\u6587", "placeholder": "\u672C\u6587", "span": 24, "rows": 8 }, on: { "change": _vm.changeEmailContent } })], 1);
};
var staticRenderFns = [];
var SendAllModal_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "SendAllModal",
  inject: ["can"],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      emailContent: "",
      sendAllUrl: `${apiUrl}/jidoseisei/efaxs/all`,
      page: JIDO_SEISEI_EFAX,
      permissions: {
        all: ALL_PERMISSION
      }
    };
  },
  computed: {
    isSendAllEfaxPermission() {
      return this.$can(this.permissions.all, this.page);
    }
  },
  methods: {
    handleCancel() {
      this.$emit("closed-modal", false);
    },
    changeEmailContent(e) {
      this.emailContent = e;
    },
    async handleOK() {
      try {
        const res = await this.axios.post(this.sendAllUrl, {
          saleManId: this.folderId,
          body: this.emailContent
        });
        if (res && res.data) {
          this.handleCancel();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var SendAllModal = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { SendAllModal as default };
